/* src/TwentyFourYears.css */
.game-container {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  button {
    margin: 5px;
    padding: 10px;
    font-size: 16px;
  }
  
  h1 {
    color: #333;
  }
  
  h2 {
    color: #666;
  }
  